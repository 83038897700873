<template>
    <div class="box">
        <h2>
            Einträge
            <small>{{ crefoKeylist.key }}</small>
        </h2>
        <div class="body">
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Name</th>
                        <th>Kurzform</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="clickable-row" v-for="entry in crefoKeylist.entries" v-bind:key="entry.key">
                        <td>{{ entry.key }}</td>
                        <td>{{ entry.designation }}</td>
                        <td>{{ entry.shortdesignation }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            crefoKeylist: {
                key: "",
                entries: []
            }
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$api.get("/crefo/keylist/" + this.$route.params.id).then(
                response => {
                    this.crefoKeylist = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Einträge");
                }
            );
        }
    }
};
</script>
